.dashboard-tooltip.custom-tooltip {
  max-width: 380px;
  width: 100%;
  padding: 33px 30px;
}
.dashboard-tooltip .custom-tooltip-content {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
}
.dashboard-tooltip .custom-tooltip-footer {
  margin-top: 10px;
}
