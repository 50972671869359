* {
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "InterRegular", "InterMedium", "Open Sans",
    "Helvetica Neue", sans-serif;
  background: #161819;
  scroll-behavior: smooth;
  user-select: none;
  color: #fff;
}
input,
select {
  outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  pointer-events: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
