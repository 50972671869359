.bg-dark-gradient {
  background: linear-gradient(180deg, #0a0a0a 0%, #1a1a1a 100%);
}

.text-gradient {
  text-transform: uppercase;
  background: linear-gradient(54deg, #00F8BE 7.98%, #7350FF 79.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dynamic-height {
  min-height: calc(100vh - 128px);
}
.centred {
  margin: 0 auto;
}

.svg-custom svg {
  width: 101px;
  height: 37px;
}

.inline-block-element button {
  display: inline-block;
}

.ellipsis-text-card {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ellipsis-3-text-card {
  display: -webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient: vertical;
}