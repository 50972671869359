@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.loading-container {
  display: flex;
  margin: 32px 0;
}

.loading-dot-first,
.loading-dot-second,
.loading-dot-third {
  width: 16px;
  height: 16px;
  background: rgb(131, 133, 170);
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.loading-dot-second {
  animation-delay: 0.2s;
}

.loading-dot-third {
  animation-delay: 0.4s;
}
