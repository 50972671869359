.marketplace-tooltip.custom-tooltip {
  /*min-width: 520px;*/
  width: 100%;
}
.marketplace-tooltip .custom-tooltip-content {
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 20px;
}
.marketplace-tooltip .custom-tooltip-footer {
  margin-top: 5px;
}
