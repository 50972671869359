.sidebar-tooltip.custom-tooltip {
    max-width: 580px;
}
.sidebar-tooltip .custom-tooltip-content {
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
}
.sidebar-tooltip .custom-tooltip-footer {
    /*margin-top: 10px;*/
}
