/* global scroll styles */

::-webkit-scrollbar {
  width: 10px;
  height: calc(100% + 2px);
}

::-webkit-scrollbar-thumb {
  background: #252728;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

/* custom scroll styles for Scrollbar from "react-scrollbars-custom" */
/*.scrollBarCustomVertical {*/
/*  width: 10px !important;*/
/*  right: -1px !important;*/
/*  top: -1px !important;*/
/*  height: calc(100% + 2px) !important;*/
/*}*/
/*.scrollBarThumbVertical {*/
/*  background-color: #252728 !important;*/
/*  box-sizing: border-box;*/
/*  border-radius: 10px !important;*/
/*}*/
/*.scrollBarCustom {*/
/*  display: none;*/
/*}*/

/*
styles for scroll in the little elements
  maybe can make a bug with other scrolls in the future??
*/
.scrollbar::-webkit-scrollbar {
  width: 5px;
}
.scrollbar::-webkit-scrollbar-track {
  right: -1px !important;
  top: -1px !important;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  height: calc(100% + 2px) !important;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #252728;
}

.invisible-scrollbar::-webkit-scrollbar {
  width: 0;
}

.scroll-none::-webkit-scrollbar {
  display: none;
}
.scroll-none::-webkit-scrollbar-track {
  display: none;
}
