.loader {
  border-color: #00f8be;
  border-top-color: #3c3d3e;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  20%,
  80% {
    transform: scale(1.05) translateY(-10%);
    opacity: 0.9;
  }
  50% {
    transform: scale(0.95) translateY(5%);
    opacity: 0.75;
  }
}

.pulse {
  animation: pulse 3.5s infinite ease-in-out;
}
