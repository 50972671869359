.react-joyride__spotlight {
  display: none;
}

/* in case if overlay height will be cutted */
.react-joyride__overlay {
  /*min-height: 100vh;*/
}

.custom-tooltip {
  position: relative;
  background-color: #fff;
  border-radius: 28px;
  padding: 36px;
  max-width: 500px;
  width: 100%;
  color: black;
}

.custom-tooltip-content {
  text-align: left;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

.custom-tooltip-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  outline: none;
}

.custom-tooltip-button {
  background-color: transparent;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
}

.custom-tooltip:before {
  content: "";
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  width: 38px;
  height: 38px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 9999;
}

/*-------------------------------*/

.position-top-center:before {
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
}

.position-top-left-15:before {
  top: -16px;
  left: 15%;
  transform: translateX(-50%);
}

.position-top-right-65:before {
  top: -16px;
  left: 65%;
  transform: translateX(-50%);
}

.position-top-right-85:before {
  top: -16px;
  left: 85%;
  transform: translateX(-50%);
}

.position-bottom-center:before {
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
}

.position-left-center:before {
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
}

.position-right-center:before {
  top: 50%;
  right: -16px;
  transform: translateY(-50%);
}
