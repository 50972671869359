@font-face {
  font-family: "InterThin";
  src: url("../assets/fonts/inter/woff2/Inter-Thin.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-Thin.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-Thin.ttf") format("ttf");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "InterExtraLight";
  src: url("../assets/fonts/inter/woff2/Inter-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-ExtraLight.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-ExtraLight.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "InterLight";
  src: url("../assets/fonts/inter/woff2/Inter-Light.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-Light.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "InterRegular";
  src: url("../assets/fonts/inter/woff2/Inter-Regular.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-Regular.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "InterMedium";
  src: url("../assets/fonts/inter/woff2/Inter-Medium.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-Medium.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "InterSemiBold";
  src: url("../assets/fonts/inter/woff2/Inter-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-SemiBold.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "InterBold";
  src: url("../assets/fonts/inter/woff2/Inter-Bold.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-Bold.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "InterExtraBold";
  src: url("../assets/fonts/inter/woff2/Inter-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-ExtraBold.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-ExtraBold.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "InterBlack";
  src: url("../assets/fonts/inter/woff2/Inter-Black.woff2") format("woff2"),
    url("../assets/fonts/inter/woff/Inter-Black.woff") format("woff"),
    url("../assets/fonts/inter/ttf/Inter-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "RobotoThin";
  src: url("../assets/fonts/roboto/woff2/Roboto-Thin.woff2") format("woff2"),
    url("../assets/fonts/roboto/woff/Roboto-Thin.woff") format("woff"),
    url("../assets/fonts/roboto/ttf/Roboto-Thin.ttf") format("ttf");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "RobotoLight";
  src: url("../assets/fonts/roboto/woff2/Roboto-Light.woff2") format("woff2"),
    url("../assets/fonts/roboto/woff/Roboto-Light.woff") format("woff"),
    url("../assets/fonts/roboto/ttf/Roboto-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "RobotoRegular";
  src: url("../assets/fonts/roboto/woff2/Roboto-Regular.woff2") format("woff2"),
    url("../assets/fonts/roboto/woff/Roboto-Regular.woff") format("woff"),
    url("../assets/fonts/roboto/ttf/Roboto-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMedium";
  src: url("../assets/fonts/roboto/woff2/Roboto-Medium.woff2") format("woff2"),
    url("../assets/fonts/roboto/woff/Roboto-Medium.woff") format("woff"),
    url("../assets/fonts/roboto/ttf/Roboto-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "RobotoBold";
  src: url("../assets/fonts/roboto/woff2/Roboto-Bold.woff2") format("woff2"),
    url("../assets/fonts/roboto/woff/Roboto-Bold.woff") format("woff"),
    url("../assets/fonts/roboto/ttf/Roboto-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "RobotoBlack";
  src: url("../assets/fonts/roboto/woff2/Roboto-Black.woff2") format("woff2"),
    url("../assets/fonts/roboto/woff/Roboto-Black.woff") format("woff"),
    url("../assets/fonts/roboto/ttf/Roboto-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
}
